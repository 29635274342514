import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenuItems } from './menu-items/menu-items';

import {
  AppMenuAnchorDirective,
  AppMenuLinkDirective,
  AppMenuDirective,
  AppSubmenuAnchorDirective,
  AppSubmenuLinkDirective,
  AppSubmenuDirective,
} from './sidebar-directive';
import { LoadingComponent } from './loading/loading.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { NgBrazil } from 'ng-brazil';
import { StepComponent } from './step/step.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Endpoints } from './endpoints/endpoints';
import { FilterComponent } from './components/filter/filter.component';
import { SlideToogleComponent } from './components/slide-toogle/slide-toogle.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { SearchComponent } from './components/search/search.component';
import { FilterCardComponent } from './components/cards/filter-card/filter-card.component';
import { FilterButtonComponent } from './components/filter-button/filter-button.component';
import { StatusCardComponent } from './components/cards/status-card/status-card.component';
import { MaterialModule } from './material-module';
import { StatusPipe } from './pipes/status.pipe';
import { PhonePipe } from './pipes/cellPhone.pipe';
import { TaxIdMaskPipe } from './pipes/tax-id-mask.pipe';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TextMaskModule,
    NgBrazil,
    FlexLayoutModule,
    MaterialModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  declarations: [
    AppMenuAnchorDirective,
    AppMenuLinkDirective,
    AppMenuDirective,
    AppSubmenuAnchorDirective,
    AppSubmenuLinkDirective,
    AppSubmenuDirective,
    LoadingComponent,
    StepComponent,
    FilterComponent,
    SlideToogleComponent,
    DropdownComponent,
    SearchComponent,
    FilterCardComponent,
    FilterButtonComponent,
    StatusCardComponent,
    StatusPipe,
    PhonePipe,
    TaxIdMaskPipe
  ],
  exports: [
    AppMenuAnchorDirective,
    AppMenuLinkDirective,
    AppMenuDirective,
    AppSubmenuAnchorDirective,
    AppSubmenuLinkDirective,
    AppSubmenuDirective,
    LoadingComponent,
    FormsModule,
    TextMaskModule,
    NgBrazil,
    StepComponent,
    FilterComponent,
    SlideToogleComponent,
    DropdownComponent,
    SearchComponent,
    FilterCardComponent,
    FilterButtonComponent,
    StatusCardComponent,
    StatusPipe,
    PhonePipe,
    TaxIdMaskPipe,
    TranslateModule
  ],
  providers: [MenuItems, Endpoints]
})
export class SharedModule { }
