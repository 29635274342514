import { IProfilePermissions } from "../models/profiles-accesses/profile-permissions";
import { ColorPalette } from "../models/settings/system-settings";

export class ConfigApp {
    public static configStyle(color_palette: ColorPalette): void {
        Object.entries(color_palette).forEach(([key, value]) => {
            if (value.color_value != undefined) {
                if (value.color_value.substr(0, 4) == '0xff')
                    document.documentElement.style.setProperty(`--${key}`, value.color_value.replace('0xff', '#'));
            }
        })

    }

    public static validateItemMenu(menuName: string, configs: any, permissions: IProfilePermissions | null, type: string): boolean {
        switch (menuName) {
            case "painel":
                return true;
            case "conta-corrente":
                return true;
            case "pagar":
                return configs.pagar_conta.enabled && type === 'PJ';
            case "pagamentos":
                return configs.pagar_conta.enabled && type === 'PF';
            case "transferencia":
                return configs.enviar_dinheiro.enabled && type === 'PJ';
            case "transferencias":
                return configs.enviar_dinheiro.enabled && type === 'PF';
            case "cobrar":
                return configs.receber_dinheiro.enabled;
            case "depositar":
                return configs.colocar_dinheiro.enabled;
            case "pix":
                return configs.pix.enabled;
            case "aprovacoes":
                return true;
            case "comprovantes":
                return true;
            case "investimentos":
                return permissions !== null && (
                    permissions.query_investiment.allow ||
                    permissions.make_investiment.allow ||
                    permissions.withdraw_investiment.allow
                );
            case "cobranca":
                return ((configs.receber_dinheiro?.cobranca?.boleto_enabled ?? false) && 
                    permissions !== null &&
                    permissions.create_slip.allow);
            case "emprestimo":
                return (configs.receber_dinheiro?.cobranca?.boleto_enabled ?? false);
            case "servicos":
                return permissions !== null && (permissions.manage_config_profile.allow ||
                    permissions.manage_operator.allow || permissions.manage_competence_profile.allow);
            case "recarga-telefone":
                return configs.recarga_de_celular.enabled;
            // case "conta-pool":
            // case "dashboard-pool":    
            //     return permissions !== null && permissions.view_pool_options !== undefined && permissions.view_pool_options.allow;  
            default:
                return true;
        }
    }


    public static validateSubItemMenu(menuName: string, configs: any, permissions: IProfilePermissions | null, type: string): boolean {
        switch (menuName) {
            case "gestao-perfis":
                return permissions ? permissions.manage_config_profile.allow : false;
            case "gestao-acessos":
                return permissions ? permissions.manage_operator.allow : false;
            case "alcadas-servico":
                return permissions ? permissions.manage_competence_profile.allow : false;
            case "extrato":
            case "saldo":
                return configs.extrato_completo.enabled && (permissions?.query_balance_statement.allow ?? false);
            case "cobrancas-emitidas":
                return configs.pix.advanced_pix_qrcode_support;
            case "cobrancas":
                return configs.receber_dinheiro.cobranca.lista_cobranca_enabled;
            case "solicitacoes":
                return configs.profile?.solicitacoes?.enabled;
            case "pagar":
            case "boleto":
                return configs.pagar_conta.enabled;
            case "cobrar":
                return configs.receber_dinheiro.enabled;
            case "cartao-credito":
                return configs.cartao_prepago.enabled;
            case "pix":
            case "cobrar-pix":
                return configs.pix.enabled;
            case "dinheiro-boleto":
                return configs.colocar_dinheiro.boleto_simples_enabled;
            case "cobrar-lote":
                return configs.lotes?.emissionBatch;
            case "pagar-lote":
            case "pagar-boleto-lote":
                return type == 'PJ' && configs.lotes?.paymentBatch && (permissions?.add_payment_request.allow ?? false);
            case "lotes-de-boletos-processados":
            case "lotes-de-teds-processados":
            case "lotes-de-boletos-emitidos":
                return type == 'PJ' && configs.lotes?.extractBatch && (permissions?.add_payment_request.allow ?? false);
            case "cobrar-boleto":
                return configs.receber_dinheiro.cobranca.boleto_enabled;
            case "consulta-bordero":
            case "consulta-titulos":
                return permissions?.query_slip.allow ?? false;
            case "posicao-carteira":
            case "fundo-investimento":
            case "perfil-investidor":
                return permissions?.query_investiment.allow ?? false;
            case "saldo-vinculada":
                return permissions?.query_escrow_account.allow ?? false;
            case "incluir-novas-garantias":
            case "nova-cessao":
                return permissions?.create_slip.allow ?? false;
            case "pagar-boleto":
            case "pagar-concessionarias":
            case "pagar-tributos":
            case "pagar-dda":
            case "lotes-de-boletos-emitidos":
                return permissions?.add_payment_request.allow ?? false
            case "via-chave-pix":
            case "via-dados-conta":
            case "pix-copia-cola":
            case "enviar-dinheiro-pix":
                return permissions?.add_pix.allow ?? false;
            case "aplicar-investimento":
                return permissions?.make_investiment?.allow ?? false;
            case "resgatar-investimento":
                return permissions?.withdraw_investiment?.allow ?? false;
            case "solicitar-transferencia":
                return permissions?.withdraw_scrow_account?.allow ?? false;
            case 'enviar-dinheiro-interna-ted':
                return (permissions?.add_transfer_common_owner.allow ?? false) || (permissions?.add_transfer_different_owner.allow ?? false);
            case "transferir":
                return (permissions?.add_transfer_different_owner.allow ?? false) && configs.enviar_dinheiro.enabled;
            case 'ted-lotes':
                return (permissions?.add_transfer_common_owner.allow ?? false) && (permissions?.add_transfer_different_owner.allow ?? false) && type == 'PJ' && configs.lotes?.transferBatch;
            case 'cobranca-simples':
            case 'cobranca-avancada':
                return configs.receber_dinheiro?.cobranca?.pix_enabled ?? false;
            // case 'cadastro-conta-pool':
            // case 'listagem-usuario-pool':    
            //     return permissions !== null && permissions.view_pool_options !== undefined && permissions.view_pool_options.allow;                           
            default:
                return true;
        }
    }

}