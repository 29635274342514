import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';

export interface FilterOption {
  label: string;
  selected: boolean
}

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  @Input() filterOptions: FilterOption[] = []

  @Output() changeOption = new EventEmitter<FilterOption>()

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
  }

  selectOption(index: number): void {
    this.filterOptions.forEach(i => i.selected = false);
    this.filterOptions[index].selected = true;
    this.changeOption.emit(this.filterOptions[index]);
  }

  capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  translateValue(value: string): string {
    let valueCorrect = {
      'Todos': 'All',
      'Pendentes': 'Pending',
      'Ativos': 'Active',
      'Recusados': 'Rejected',

    }
  
    //@ts-ignore
    return this.translate.instant(valueCorrect[value] || value);
  }

}
