<div *ngIf="!miniSideBar"
     class="company-logo"
     fxLayoutAlign="center center">
    <div style="display: flex; flex-direction: column; align-items: center; gap: 6px;">
        <img *ngIf="mobileQuery.matches"
             [src]="this.logo"
             alt="logo"
             style="width: 120px;">
    </div>
    <mat-icon class="close-menu"
              (click)="closeMenu()"
              *ngIf="!mobileQuery.matches">close</mat-icon>
</div>

<div *ngIf="miniSideBar"
     class="company-logo"
     fxLayoutAlign="center center">
    <img class="company-logo-icon"
         [src]="this.logo"
         alt="logo">
</div>

<div fxLayoutAlign="center center">
    <button (click)="switchLanguage('pt-BR')"
            class="language-button"
            [ngClass]="{'language-button-selected': currentLanguage === 'pt-BR'}">
      <div class="faIcon">
        <img src="../../../../assets/images/flags/BR.svg"
             alt="Português"
             [ngStyle]="{'width.px': miniSideBar ? 15 : null}">
      </div>
    </button>
  
    <button (click)="switchLanguage('en')"
            class="language-button"
            [ngClass]="{'language-button-selected': currentLanguage === 'en'}">
      <div class="faIcon">
        <img src="../../../../assets/images/flags/US.svg"
             alt="English"
             [ngStyle]="{'width.px': miniSideBar ? 15 : null}">
      </div>
    </button>
</div>

<mat-nav-list appMenu>
    <mat-list-item appMenuLink
                   *ngFor="let menuitem of menu"
                   routerLinkActive="selected"
                   [ngClass]="setSelectedSubmenu(formatRouterLink(menuitem.module, menuitem.state, ''))"
                   group="{{menuitem.state}}"
                   (click)="scrollToTop()">

        <a appMenuToggle
           [routerLink]="[menuitem.module, menuitem.state]"
           *ngIf="(menuitem.type === 'link') && validateItemMenu(menuitem.state) && !menuitem.noAction"
           [style.justify-content]="miniSideBar ? 'start' : 'unset'">

            <span>{{ translateValue(menuitem.name) }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}"
                  *ngFor="let badge of menuitem.badge">{{ badge.value}}</span>
        </a>

        <a [matTooltip]="'Nesse momento você não possui conta vinculada, caso tenha interesse fale com o seu Gerente'"
           *ngIf="(menuitem.type === 'link') && validateItemMenu(menuitem.state) && menuitem.noAction"
           [style.justify-content]="miniSideBar ? 'start' : 'unset'">

            <span style="color: rgba(255, 255, 255, 0.3);">{{ translateValue(menuitem.name) }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}"
                  *ngFor="let badge of menuitem.badge">{{ badge.value}}</span>
        </a>

        <a appMenuToggle
           [href]="menuitem.state"
           *ngIf="(menuitem.type === 'external-link')"
           [style.justify-content]="miniSideBar ? 'start' : 'unset'">

            <span>{{ translateValue(menuitem.name) }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}"
                  *ngFor="let badge of menuitem.badge">{{ badge.value}}</span>
        </a>

        <a appMenuToggle
           href="javascript:;"
           *ngIf="menuitem.type === 'sub' && validateItemMenu(menuitem.state)"
           class="app-menu-toggle"
           [style.justify-content]="miniSideBar ? 'start' : 'unset'"
           (click)="menuTrigger.toggleMenu()"
           [ngClass]="{'escrow' : menuitem.state == 'conta-escrow'}">

            <!-- se PF, exibir somente menu como "Pagamentos"
                 se PJ, exibir "Pagamentos à Fornecedores" -->

            <span style="white-space: break-spaces;"
                  *ngIf="selectedAccount?.type === 'PF' && menuitem.name === 'Pagamentos à Fornecedores'; else teste">Pagamentos</span>

            <ng-template #teste>
                <span style="white-space: break-spaces;">{{ translateValue(menuitem.name) }}</span>
            </ng-template>

            <span fxFlex></span>
            <span class="label label-{{ badge.type }}"
                  *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
            <mat-icon class="dd-icon">keyboard_arrow_right</mat-icon>
            <div #menuTrigger="matMenuTrigger"
                 [matMenuTriggerFor]="submenu"
                 (menuOpened)="onOpenedSubmenu()"
                 (menuClosed)="onClosedSubmenu()"></div>
        </a>

        <mat-menu appSubmenu
                  #submenu="matMenu"
                  class="mat-sub-menu">

            <div style="font-family: 'Gotham Bold';">
                <!-- se PF, exibir somente menu como "Pagamentos"
                     se PJ, exibir "Pagamentos à Fornecedores" -->
                <span style="white-space: break-spaces;"
                      *ngIf="selectedAccount?.type === 'PF' && menuitem.name === 'Pagamentos à Fornecedores'; else teste">Pagamentos</span>

                <ng-template #teste>
                    <span style="white-space: break-spaces;"
                          class="title-sub-menu">{{ translateValue(menuitem.name) }}</span>
                </ng-template>
            </div>

            <div *ngIf="!menuitem.subtype"
                 style="margin-top: 20px;">
                <content *ngFor="let childitem of menuitem.children; let j = index;">
                    <a mat-menu-item
                       appSubmenuLink
                       [routerLink]="['/', menuitem.module, menuitem.state, childitem.state]"
                       class="sub-item"
                       [style.margin-bottom]="childitem.padding ? '30px' : '0px'"
                       *ngIf="validateSubItemMenu(childitem.state)"
                       [ngClass]="setSelectedSubmenu(formatRouterLink(menuitem.module, menuitem.state, childitem.state))"
                       [partialPath]="formatRouterLink(menuitem.module, menuitem.state, childitem.state)">
                        <div appSubmenuToggle
                             fxLayout="row"
                             fxLayoutAlign="start center">

                            <span [style.color]="childitem.goldColor ? '#9C8555 !important': ''">{{ translateValue(childitem.name) }} </span>
                        </div>
                    </a>
                </content>
            </div>
            <div *ngIf="menuitem.subtype === 'subtitle'">
                <content *ngFor="let childitem of menuitem.children; let j = index;">

                    <div class="subnav"
                         class="mat-elevation-z0"
                         *ngIf="validateSubItemMenu(childitem.state)">
                        <div class="subnav-header"
                             (click)="closeHeaderSubnav($event, childitem)">
                            <div fxLayout="row"
                                 fxLayoutAlign="start center">

                                <span>{{ translateValue(childitem.name) }}</span>
                            </div>
                            <i *ngIf="childitem.active"
                               class="fas fa-chevron-up subnav-icon"></i>
                            <i *ngIf="!childitem.active"
                               class="fas fa-chevron-down subnav-icon"></i>
                        </div>
                        <div class="subnav-body"
                             *ngIf="childitem.active">
                            <content *ngFor="let subchildrenItem of childitem.subchildren; let j = index;">
                                <a mat-menu-item
                                   appSubmenuLink
                                   [routerLink]="['/', menuitem.module, menuitem.state, subchildrenItem.state]"
                                   class="sub-item"
                                   [ngClass]="setSelectedSubmenu(formatRouterLink(menuitem.module, menuitem.state, subchildrenItem.state))"
                                   [partialPath]="formatRouterLink(menuitem.module, menuitem.state, subchildrenItem.state)"
                                   (click)="track(subchildrenItem.name)">
                                    <div appSubmenuToggle>
                                        {{ translateValue(subchildrenItem.name) }}
                                    </div>
                                </a>
                            </content>
                        </div>
                    </div>
                </content>
            </div>

        </mat-menu>

    </mat-list-item>

</mat-nav-list>

<div style="align-self:flex-end"
     [ngClass]="{'user-profile2' : !miniSideBar, 'user-profile2-mini' : miniSideBar}"
     *ngIf="mobileQuery.matches">
    <div class="accounts"
         fxLayout="row wrap"
         fxLayoutAlign="center center">

        <div fxFlex="100"
             fxLayout="row">

            <div fxLayout="row"
                 fxFlex="100"
                 fxLayoutAlign="start center">
                <div fxFlex="18"
                     class="m-r-10"
                     (click)="openPopUp()">
                    <img *ngIf="image"
                         [src]="image"
                         style="border-radius: 50%;">
                    <div *ngIf="!image"
                         class="user-profile-menu-no-photo">
                        <span> {{getInitialFromName()}}</span>
                    </div>
                </div>

                <div fxFlex="72"
                     (click)="openPopUp()">
                    <span class="user-name">{{getTypeAndNameAccount(selectedAccount?.holderTaxId, selectedAccount?.holderName)}}</span>
                    <div class="user-account">
                        <span>Conta: {{selectedAccount?.account ? selectedAccount?.account : "MASTER"}}</span>
                    </div>
                </div>

                <div fxFlex="10"
                     (click)="menuUserTrigger.openMenu()"
                     fxLayout="column"
                     fxLayoutAlign="center center">
                    <mat-icon style="padding-left: 8px !important;">arrow_right</mat-icon>
                </div>
            </div>

        </div>

        <div #menuUserTrigger="matMenuTrigger"
             [matMenuTriggerFor]="submenuUser"
             (menuOpened)="onOpenedSubmenu()"
             (menuClosed)="onClosedSubmenu()"></div>
    </div>
    <div *ngIf="accounts.length > 1 && open"
         class="accounts-pop">
        <div (click)="chosenAccount(i)"
             *ngFor="let account of accounts; let i = index"
             [class.account-select]="selectedAccount?.accountIndex !== account.accountIndex"
             [class.account-selected]="selectedAccount?.accountIndex === account.accountIndex">
            <div>
                <div>
                    <span>{{getTypeAndNameAccount(account?.operatorConfigProfile?.userHolderTaxId, account?.operatorConfigProfile?.userHolderName)}}</span>
                </div>
                <div>
                    {{account.accountIndex.replace("_", " ")}}
                </div>
            </div>
        </div>
    </div>
</div>

<mat-menu #submenuUser="matMenu"
          class="mat-sub-menu mat-sub-menu-profile">

    <h3 class="title">
        {{ translateValue(name) }}
    </h3>

    <div class="perfil"
         (click)="showPerfil()">
        {{"My-profile" | translate}}
    </div>

    <div class="perfil"
         (click)="showAbout()">
         {{"About" | translate}}
    </div>

    <div class="perfil"
         (click)="logout()">
         {{"Exit" | translate}}
    </div>

</mat-menu>